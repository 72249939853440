import { Menu } from 'antd';
import React from 'react';
import './DZMenu.css'
import DZText from '../text/DZText';
import { Loader } from '../loaders/Loaders';

const DZMenu = ({ categories, setSelectedCategoryId, setCurrentPage, allProducts }) => {

    const convertToMenuItems = (categories) => {
        return (categories?.map(category => {
            const category_id = category?.id;
            // const isProductsAvailable = allProducts?.filter((prod) => prod?.category_id == category_id)?.length > 0;
            const menuItem = {
                label: <DZText children={category?.name} />,
                key: `opt${category_id}`,
                onClick: () => {
                    if (!category?.subcategories || category?.subcategories?.length === 0) {
                        setSelectedCategoryId(category?.id);
                        setCurrentPage(1);
                    }
                }
            };

            if (category?.subcategories && category?.subcategories?.length > 0) {
                menuItem.children = convertToMenuItems(category?.subcategories);
            };

            return menuItem;
        })) ?? []
    };
    const allCategoriesItem = { label: <DZText children={'Common.all_categories'}/>, key: 'all', onClick: () => { setSelectedCategoryId(1); setCurrentPage(1) } };
    const items = [allCategoriesItem, ...convertToMenuItems(categories)];

    return (
        <>
            {
                categories ? <Menu items={items} defaultSelectedKeys={['all']} style={{ borderWidth: 0 }} /> : <Loader svgClassName={'w-40 h-40'} />
            }
        </>
    );
};

export default DZMenu;

// import React, { useState } from 'react';
// import { Menu } from 'antd';
// import { KEYS } from '../../constants/Index';

// const DZMenu = () => {

//     const [currentCategoryId, setCurrentCategoryId] = useState(null);
//     const selectedLanguage = localStorage.getItem(KEYS.lang);

//     let products = [
//         {
//             "id": 29,
//             "name": {
//                 "en": "Burger",
//                 "ar": "برجر",
//                 "dk": "Burger",
//                 "tr": "Etli ekmek"
//             },
//             "parent_id": null,
//             "order": "8",
//             "image": "29-1703866786-25473-the-perfect-basic-burger-DDMFS-4x3-56eaba3833fd4a26a82755bcd0be0c54.jpg",

//             "subcategories": [
//                 {
//                     "id": 30,
//                     "name": {
//                         "en": "Pizza",
//                         "dk": "Pizza",
//                         "ar": "بيتزا",
//                         "tr": "Pizza"
//                     },
//                     "parent_id": "29",
//                     "order": "1",
//                     "image": "30-1703866642-classic-cheese-pizza-FT-RECIPE0422-31a2c938fc2546c9a07b7011658cfd05.jpg",
//                     "subcategories": [
//                         {
//                             "id": 31,
//                             "name": {
//                                 "en": "Donuts",
//                                 "ar": "دونات",
//                                 "dk": "Donuts"
//                             },
//                             "parent_id": "30",
//                             "order": "2",
//                             "image": "31-1703866663-FunCakes-recept-delicious-donuts-website-1-1000x750.jpg",
//                             "subcategories": [
//                                 {
//                                     "id": 32,
//                                     "name": {
//                                         "en": "Salads",
//                                         "ar": "سلطة",
//                                         "dk": "Salater"
//                                     },
//                                     "parent_id": "31",
//                                     "order": "3",
//                                     "image": "32-1703866678-hot-smoked-salmon-salad-6f16c73.jpg",
//                                     "subcategories": [
//                                         {
//                                             "id": 33,
//                                             "name": {
//                                                 "en": "Sweets",
//                                                 "ar": "حلويات",
//                                                 "dk": "Søde sager"
//                                             },
//                                             "parent_id": "32",
//                                             "order": "4",
//                                             "image": "33-1703866764-Unhealthy-Food-Chart.jpg",
//                                             "subcategories": [
//                                                 {
//                                                     "id": 34,
//                                                     "name": {
//                                                         "en": "Icecream12",
//                                                         "ar": "بوظة",
//                                                         "dk": "flødeis"
//                                                     },
//                                                     "parent_id": "33",
//                                                     "order": "5",
//                                                     "image": "34-1703866775-shutterstock_92449741.jpg",
//                                                     "subcategories": []
//                                                 }
//                                             ]
//                                         }
//                                     ]
//                                 }
//                             ]
//                         }
//                     ]
//                 }
//             ]
//         },
//         {
//             "id": 40,
//             "name": {
//                 "en": "Soft drinks",
//                 "ar": "المشروبات الغازية",
//                 "dk": "Læskedrikke"
//             },
//             "parent_id": null,
//             "order": "9",
//             "image": "40-1703866797-soft-drink-cans-800x400.jpg",

//             "subcategories": []
//         }
//     ];

//     const convertToMenuItems = (categories) => {
//         return categories?.map((category) => {
//             const category_id = category?.id;
//             const isProductsAvailable =
//                 products?.filter((prod) => prod?.parent_id === category_id)?.length > 0;

//             const menuItem = {
//                 label: category?.name?.[selectedLanguage],
//                 key: `opt${category_id}`,
//             };

//             if (category?.subcategories && category?.subcategories?.length > 0) {
//                 menuItem.children = convertToMenuItems(category?.subcategories);
//             }

//             if (isProductsAvailable || (category?.subcategories && category?.subcategories?.length > 0)) {
//                 menuItem.onClick = () => handleMenuItemClick(category_id);
//             }

//             return menuItem;
//         });
//     };

//     const handleMenuItemClick = (categoryId) => {
//         setCurrentCategoryId(categoryId);
//         // Add your logic here when a menu item is clicked
//         console.log('Selected Category ID:', categoryId);
//     };

//     const items = convertToMenuItems(products);

//     return <Menu mode="vertical" selectedKeys={[`opt${currentCategoryId}`]} items={items} />;
// };

// export default DZMenu;