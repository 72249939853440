import React, { useState } from "react";
import HeroSection from "./components/HeroSection/HeroSection.jsx";
// import CategorySection from "./components/CategorySection/CategorySection.jsx";
// import ProductSection from "./components/ProductSection/ProductSection.jsx";
// import AboutUs from "./components/aboutUs/AboutUs.jsx";
// import ReviewClient from "./components/reviewClient/ReviewClient.jsx";
// import PopularItems from "./components/popularItems/PopularItems.jsx";
// import CardServices from "./components/cardServices/CardServices.jsx";
import './Home.css';

// import ViewProductCard from '../../components/views/ViewProductCard.jsx'
// import { useGetAllDealsQuery } from '../../redux/storeApis'
// import DZText from "../../components/text/DZText.jsx";
import { useLinksManager } from "../../lib/customHooks/useLinksManager.js";
import GoogleMapMark from "../../components/google-map/GoogleMapMark.js";
import { Config } from "../../constants/Index.js";
import Gallery from "../../components/gallery/Gallery.js";

const Home = () => {

  // const { data: allDeals } = useGetAllDealsQuery();
  const { restaurant_phone, restaurant_email, restaurant_address, restaurant_lat, restaurant_long } = useLinksManager();

  const markers = [
    {
      id: 1,
      name: "Restaurant",
      position: {
        lat: Number(restaurant_lat),
        lng: Number(restaurant_long)
      },
    },
  ];

  return (
    <>

      {/* <HeroSection /> */}

      {/* <div className="d-flex align-center justify-center w-full" style={{ margin: '80px 0' }}>

        <div className="max-inner d-flex align-center justify-center gap-32  w-full sm-flex-col">

          <div style={{ background: '#88c74a40' }} className="d-flex align-center h-320 w-full h-full position-rel md-pr-3 pb-3 pl-3 pt-3">

            <div className="d-flex align-start gap-20 flex-col">

              <div className="d-flex align-start gap-10 flex-col  max-w-300">

                <span className="fs-32 fw-500 dark-color">
                  Free delivery over $50
                </span>

                <span className="fs-18 fw-400 dark-color">
                  Shop $50 product and get free delivery anywhre.
                </span>

              </div>

              <span className="fs-14 fw-400 white-color green-back p-12 cursor-p">
                Shop Now
              </span>

            </div>

            <img src="images/Offer1-img.png" className="position-abs b-0 r-0 sm-d-none" alt="" style={{ maxHeight: '320px', maxWidth: '240px' }} />

          </div>

          <div style={{ background: '#88c74a40' }} className="d-flex align-center h-320 w-full h-full position-rel md-pr-3 pb-3 pl-3 pt-3">

            <div className="d-flex align-start gap-20 flex-col">

              <div className="d-flex align-start gap-10 flex-col  max-w-300">

                <span className="fs-32 fw-500 dark-color">
                  Organic Food
                </span>

                <span className="fs-18 fw-400 dark-color">
                  Save up to 60% off on your first order
                </span>

              </div>

              <span className="fs-14 fw-400 white-color green-back p-12 cursor-p">
                Shop Now
              </span>

            </div>

            <img src="images/Offer2-img.png" className="position-abs b-0 r-0 sm-d-none" alt="" style={{ maxHeight: '320px', maxWidth: '240px' }} />

          </div>

        </div>

      </div > */}

      {/* <div className="d-flex align-center h-500 w-full sm-flex-col">

        <div className="d-flex flex-col justify-center green-back gap-24 w-full h-full align-start" style={{ padding: '104px 40px' }}>

          <div className="d-flex align-center gap-12">

            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
              <path d="M2.77762 10.9424C1.8296 9.28931 1.37185 7.93948 1.09584 6.57121C0.687622 4.54758 1.62181 2.57081 3.16938 1.30947C3.82345 0.776383 4.57323 0.958518 4.96 1.6524L5.83318 3.21891C6.52529 4.46057 6.87134 5.08139 6.8027 5.73959C6.73407 6.39779 6.26737 6.93386 5.33397 8.00601L2.77762 10.9424ZM2.77762 10.9424C4.69651 14.2883 7.70784 17.3013 11.0576 19.2224M11.0576 19.2224C12.7107 20.1704 14.0605 20.6282 15.4288 20.9042C17.4524 21.3124 19.4292 20.3782 20.6905 18.8306C21.2236 18.1766 21.0415 17.4268 20.3476 17.04L18.7811 16.1668C17.5394 15.4747 16.9186 15.1287 16.2604 15.1973C15.6022 15.2659 15.0661 15.7326 13.994 16.666L11.0576 19.2224Z" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
            </svg>

            <span className="fs-16 white-color">
              {restaurant_phone}
            </span>

          </div>

          <div className="d-flex align-center gap-12">

            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
              <path d="M1 4L7.91302 7.91697C10.4616 9.36101 11.5384 9.36101 14.087 7.91697L21 4" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
              <path d="M1.01577 11.4756C1.08114 14.5412 1.11383 16.0739 2.24496 17.2094C3.37608 18.3448 4.95033 18.3843 8.09883 18.4634C10.0393 18.5122 11.9607 18.5122 13.9012 18.4634C17.0497 18.3843 18.6239 18.3448 19.7551 17.2094C20.8862 16.0739 20.9189 14.5412 20.9842 11.4756C21.0053 10.4899 21.0053 9.51008 20.9842 8.52439C20.9189 5.45886 20.8862 3.92609 19.7551 2.79066C18.6239 1.65523 17.0497 1.61568 13.9012 1.53657C11.9607 1.48781 10.0393 1.48781 8.09882 1.53656C4.95033 1.61566 3.37608 1.65521 2.24495 2.79065C1.11382 3.92608 1.08114 5.45885 1.01576 8.52438C0.994745 9.51007 0.994745 10.4899 1.01577 11.4756Z" stroke="white" stroke-width="1.5" stroke-linejoin="round" />
            </svg>

            <span className="fs-16 white-color">
              {restaurant_email}
            </span>

          </div>

          <div className="d-flex align-center gap-12">

            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" viewBox="0 0 20 22" fill="none">
              <path d="M11.6177 20.367C11.1841 20.773 10.6044 21 10.0011 21C9.39785 21 8.81818 20.773 8.38449 20.367C4.41302 16.626 -0.909237 12.4469 1.68627 6.37966C3.08963 3.09916 6.45834 1 10.0011 1C13.5439 1 16.9126 3.09916 18.316 6.37966C20.9082 12.4393 15.599 16.6389 11.6177 20.367Z" stroke="white" stroke-width="1.5" />
              <path d="M13.5 10C13.5 11.933 11.933 13.5 10 13.5C8.067 13.5 6.5 11.933 6.5 10C6.5 8.067 8.067 6.5 10 6.5C11.933 6.5 13.5 8.067 13.5 10Z" stroke="white" stroke-width="1.5" />
            </svg>

            <span className="fs-16 white-color">
              {restaurant_address}
            </span>

          </div>

          <span onClick={() => Config.fnOpenLinkInNewTab("https://www.google.com/maps/place/" + restaurant_address)} className="p-12 white-back green-color d-flex align-center justify-center gap-12 cursor-p">

            See location on map

            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M3 9C3 8.58579 3.33579 8.25 3.75 8.25H14.25C14.6642 8.25 15 8.58579 15 9C15 9.41421 14.6642 9.75 14.25 9.75H3.75C3.33579 9.75 3 9.41421 3 9Z" fill="#88C74A" />
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.46967 3.21967C8.76256 2.92678 9.23744 2.92678 9.53033 3.21967L14.7803 8.46967C15.0732 8.76256 15.0732 9.23744 14.7803 9.53033L9.53033 14.7803C9.23744 15.0732 8.76256 15.0732 8.46967 14.7803C8.17678 14.4874 8.17678 14.0126 8.46967 13.7197L13.1893 9L8.46967 4.28033C8.17678 3.98744 8.17678 3.51256 8.46967 3.21967Z" fill="#88C74A" />
            </svg>

          </span>

        </div>

        {markers && <GoogleMapMark markers={markers} />}

      </div> */}

      <Gallery/>



    </>
  );
};

export default Home;
