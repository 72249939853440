import React from "react";
import DZText from "../../components/text/DZText";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t } = useTranslation();
  const aboutUsDetail = t("About_us.about_us_detail");
  const paragraphs = aboutUsDetail?.split('\n')?.map((paragraph, index) => (
    <p key={index} className="fs-16 dark-color">{paragraph}</p>
  ));
  return (
    <div className="d-flex flex-col align-center justify-center w-full">
      <div className="d-flex gap-24 max-inner align-center w-full p-48-0 md-flex-col">
        <div className="d-flex flex-col gap-32">
          <span className="fs-36 fw-600 dark-color">
            <span className="border-b-3-green pb-1 xxsm-border-0">The S</span>
            tory About Us
          </span>
          {paragraphs}
        </div>

        {/* <div className="w-full position-rel overflow-h h-400">
          <img
            src="https://ogami-react.vercel.app/assets/images/pages/contact/video.png"
            alt=""
            className="position-abs h-full"
          />
        </div> */}
      </div>

      {/* <div className="d-flex align-center gap-24 p-48-0 border-grey max-inner radi-2 w-full xxsm-d-flex xxsm-flex-col sm-border-0 sm-p-b-20 sm-p-t-20">
        <div className="d-flex align-center gap-20 justify-center w-full">
          <img
            src="https://ogami-react.vercel.app/assets/images/pages/contact/ic-1.png"
            alt=""
          />

          <div className="d-flex flex-col">
            <span className="fs-32 fw-500 dark-color">100%</span>

            <span className="fs-14 fw-400 dark-color">
              <DZText>{"About_us.happy_client"}</DZText>
            </span>
          </div>
        </div>

        <div className="d-flex align-center gap-20 justify-center w-full">
          <img
            src="https://ogami-react.vercel.app/assets/images/pages/contact/ic-2.png"
            alt=""
          />

          <div className="d-flex flex-col">
            <span className="fs-32 fw-500 dark-color">142</span>

            <span className="fs-14 fw-400 dark-color">
              <DZText>{"About_us.our_engineer"}</DZText>
            </span>
          </div>
        </div>

        <div className="d-flex align-center gap-20 justify-center w-full">
          <img
            src="https://ogami-react.vercel.app/assets/images/pages/contact/ic-3.png"
            alt=""
          />

          <div className="d-flex flex-col">
            <span className="fs-32 fw-500 dark-color">+16</span>

            <span className="fs-14 fw-400 dark-color">
              <DZText>{"About_us.our_stores"}</DZText>
            </span>
          </div>
        </div>
      </div>

      <div className="d-flex align-center justify-center gap-24 mt-4 light-back w-full sm-p-b-40">
        <div className="d-flex align-center gap-24 w-full mt-4 max-inner">
          <img
            src="https://ogami-react.vercel.app/assets/images/sections/introduction/five/1.png"
            className="sm-d-none"
            alt=""
          />

          <div className="d-flex flex-col w-full">
            <h2 className="fs-32 fw-600 dark-color">
              <span className="border-b-4-green pb-1">
                <DZText children={"About_us.why"} />
              </span>
              &nbsp;
              <DZText children={"About_us.choose_us"} />
            </h2>

            <div className="mt-4 d-grid grid-colt-2 gap-24 w-full xxsm-d-flex xxsm-flex-col">
              <div className="d-flex align-center gap-12 w-full sm-w-fit">
                <div className="d-flex align-center white-back justify-center p-10-8 full-radi shadow-lg">
                  <img
                    src="https://ogami-react.vercel.app/assets/images/sections/dale-of-week/one/1.png"
                    className="w-28 h-24"
                    alt=""
                  />
                </div>

                <div className="d-flex flex-col gap-8">
                  <span className="fs-16 fw-500 dark-color">Eat Healthier</span>
                  <span className="fs-14 fw-400 dark-color">
                    Modi tempora incidunt ut labore dolore magnam aliquam
                  </span>
                </div>
              </div>

              <div className="d-flex align-center gap-12 w-full sm-w-fit">
                <div className="d-flex align-center white-back justify-center p-10-8 full-radi shadow-lg">
                  <img
                    src="https://ogami-react.vercel.app/assets/images/sections/dale-of-week/one/2.png"
                    className="w-28 h-24"
                    alt=""
                  />
                </div>

                <div className="d-flex flex-col gap-8">
                  <span className="fs-16 fw-500 dark-color">Eat Healthier</span>
                  <span className="fs-14 fw-400 dark-color">
                    Modi tempora incidunt ut labore dolore magnam aliquam
                  </span>
                </div>
              </div>

              <div className="d-flex align-center gap-12 w-full sm-w-fit">
                <div className="d-flex align-center white-back justify-center p-10-8 full-radi shadow-lg">
                  <img
                    src="https://ogami-react.vercel.app/assets/images/sections/dale-of-week/one/3.png"
                    className="w-28 h-24"
                    alt=""
                  />
                </div>

                <div className="d-flex flex-col gap-8">
                  <span className="fs-16 fw-500 dark-color">Eat Healthier</span>
                  <span className="fs-14 fw-400 dark-color">
                    Modi tempora incidunt ut labore dolore magnam aliquam
                  </span>
                </div>
              </div>

              <div className="d-flex align-center gap-12 w-full sm-w-fit">
                <div className="d-flex align-center white-back justify-center p-10-8 full-radi shadow-lg">
                  <img
                    src="https://ogami-react.vercel.app/assets/images/sections/dale-of-week/one/4.png"
                    className="w-28 h-24"
                    alt=""
                  />
                </div>

                <div className="d-flex flex-col gap-8">
                  <span className="fs-16 fw-500 dark-color">Eat Healthier</span>
                  <span className="fs-14 fw-400 dark-color">
                    Modi tempora incidunt ut labore dolore magnam aliquam
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex align-center gap-48 justify-center flex-col mt-4 max-inner w-full">
        <span className="fs-32 fw-600 dark-color">
          We a<span className="border-b-4-green pb-1">re far</span>
          mer
        </span>

        <div className="d-grid grid-colt-auto-226 gap-24 w-full">
          <div className="position-rel w-full h-320 overflow-h radi-10 sm-h-200">
            <img
              src="https://ogami-react.vercel.app/assets/images/sections/introduction/six/1.png"
              alt=""
              className="position-abs obj-fit"
            />
            <div className="position-abs inset-0 dark-back flex-col opacity-0 hover-op06 transition-03 d-flex gap-12 align-center justify-center">
              <span className="fs-24 fw-600 white-color sm-fs-18 text-center">
                Katie Perry
              </span>
              <div className="d-flex align-center gap-12">
                <i className="fa-brands fa-facebook-f white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-x-twitter white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-instagram white-color cursor-p transition-03 hover-green"></i>
              </div>
            </div>
          </div>

          <div className="position-rel w-full h-320 overflow-h radi-10 sm-h-200">
            <img
              src="https://ogami-react.vercel.app/assets/images/sections/introduction/six/2.png"
              alt=""
              className="position-abs obj-fit"
            />
            <div className="position-abs inset-0 dark-back flex-col opacity-0 hover-op06 transition-03 d-flex gap-12 align-center justify-center">
              <span className="fs-24 fw-600 white-color sm-fs-18 text-center">
                John Harrison
              </span>
              <div className="d-flex align-center gap-12">
                <i className="fa-brands fa-facebook-f white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-x-twitter white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-instagram white-color cursor-p transition-03 hover-green"></i>
              </div>
            </div>
          </div>

          <div className="position-rel w-full h-320 overflow-h radi-10 sm-h-200">
            <img
              src="https://ogami-react.vercel.app/assets/images/sections/introduction/six/3.png"
              alt=""
              className="position-abs obj-fit"
            />
            <div className="position-abs inset-0 dark-back flex-col opacity-0 hover-op06 transition-03 d-flex gap-12 align-center justify-center">
              <span className="fs-24 fw-600 white-color sm-fs-18 text-center">
                Jane Cooper
              </span>
              <div className="d-flex align-center gap-12">
                <i className="fa-brands fa-facebook-f white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-x-twitter white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-instagram white-color cursor-p transition-03 hover-green"></i>
              </div>
            </div>
          </div>

          <div className="position-rel w-full h-320 overflow-h radi-10 sm-h-200">
            <img
              src="https://ogami-react.vercel.app/assets/images/sections/introduction/six/1.png"
              alt=""
              className="position-abs obj-fit"
            />
            <div className="position-abs inset-0 dark-back flex-col opacity-0 hover-op06 transition-03 d-flex gap-12 align-center justify-center">
              <span className="fs-24 fw-600 white-color sm-fs-18 text-center">
                Katie Perry
              </span>
              <div className="d-flex align-center gap-12">
                <i className="fa-brands fa-facebook-f white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-x-twitter white-color cursor-p transition-03 hover-green"></i>
                <i className="fa-brands fa-instagram white-color cursor-p transition-03 hover-green"></i>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default AboutPage;
