import { useSelector } from "react-redux";
import { selectedCartSelector } from "../../redux/CartReducer";
import { Config } from "../../constants/Index";

export default function () {
    const cartItemsList = useSelector(selectedCartSelector);

    const fnGetProductQuantity = (product_id, size_id) => {
        const productCartDetail = cartItemsList.find((cart) => (cart?.id == product_id && cart?.size == size_id));
        const productQuantity = productCartDetail?.quantity;
        const count = productQuantity ?? 0;
        return count;
    };

    const fnShowCartTotalPrice = () => {
        let totalPrice = 0;
        cartItemsList.forEach(cartProd => {
            totalPrice += cartProd?.price * cartProd?.quantity;
        });
        return Config.showCurrency(totalPrice.toFixed(2));
    };

    const fnGetTotalPrice = () => {
        let totalPrice = 0;
        cartItemsList.forEach(cartProd => { totalPrice += cartProd?.price * cartProd?.quantity; });

        return totalPrice;
    };

    return {
        fnShowCartTotalPrice,
        fnGetTotalPrice,
        fnGetProductQuantity,
    }
}