import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import SwiperCore, { EffectFade, Navigation } from 'swiper/modules';

import { CheckOutlined } from '@ant-design/icons';
import { Avatar, Col, Input, Row } from 'antd';
import './HeroSection.css'
import { Divider } from 'antd';
import { useGetAllHerosQuery } from '../../../../redux/storeApis';
import { Config } from '../../../../constants/Index';

const { Search } = Input;

// SwiperCore.use([EffectFade, Navigation]);

const HeroSection = () => {
    const { data: allHerosData, isSuccess: isSuccessHerosData } = useGetAllHerosQuery();
    const sliders = allHerosData?.hero_sliders;

    const swiperOptions = {
        spaceBetween: 30,
        effect: 'fade',
        loop: true,
        navigation: true,
        modules: [EffectFade, Navigation],
        className: 'w-full hero__slider',
    };
    return (
        <>

            {isSuccessHerosData ?
                <Swiper {...swiperOptions}>
                    {sliders?.map((slider) => {
                        return (
                            <SwiperSlide>
                                <div style={{ userSelect: 'none' }} className="overflow-h hero__slide max-inner w-full d-flex align-center space-between sm-flex-col sm-gap-48 ">
                                    <img src={Config?.getHeroImage(slider?.image)} style={{height: '100%', width: '100%'}} alt="" />
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
                : <div className='w-full hero__slider' />
            }

        </>

    );
};

export default HeroSection;
