import React, { useState } from "react";
import "./TopBar.css";
import { KEYS, LANGUAGES } from "../../../../constants/Index";
import { getLocalStorage, setLocalStorage, } from "../../../localStorage";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectedLoginUser, } from "../../../../redux/UserReducer";
import { useLinksManager } from "../../../../lib/customHooks/useLinksManager";
import ViewPopUps from "../../../views/ViewPopUps";
import DZText from "../../../text/DZText";

const TopBar = () => {
  const { en, da } = LANGUAGES;
  const { i18n } = useTranslation();
  const { faceBookLink, instagramLink, linkedInLink, twitterLink, restaurantDetails } = useLinksManager();

  const selectedLanguage = getLocalStorage(KEYS.lang);
  const isLoggedIn = useSelector(selectedLoginUser);
  const [handlePopUps, setHandlePopUps] = useState(0);

  const fnChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLocalStorage(KEYS.lang, lang);
  };

  const fnHandlePopups = () => { setHandlePopUps((pre) => pre + 1); };

  return (
    <>
      <div className="top__bar w-full d-flex align-center justify-center">
        <div className="max-inner d-flex align-center space-between w-full">
          <div className="d-flex align-center gap-16 sm-d-none">
            {restaurantDetails?.data?.email && (
              <a
                href={`mailto:${restaurantDetails?.data?.email}`}
                className="d-flex align-center gap-4 dark-color hover-green cursor-pointer transition-03"
              >
                <i className="fa-solid fa-envelope"></i>
                <span className="f-12">{restaurantDetails?.data?.email}</span>
              </a>
            )}
            <div className="border-grey h-full h-20"></div>
            {restaurantDetails?.data?.phone && (
              <a
                href={`tel:${restaurantDetails?.data?.phone}`}
                className="d-flex align-center gap-4 dark-color hover-green cursor-pointer transition-03"
              >
                <i className="fa-solid fa-phone"></i>
                <span className="f-12">{restaurantDetails?.data?.phone}</span>
              </a>
            )}
          </div>
          <div className="d-flex align-center gap-24">
            <div className="d-flex  gap-8 ">
              {twitterLink && (twitterLink !== 'null') &&  (
                <a href={twitterLink} target="_blank" className="d-flex align-center  dark-color" >
                  <i className="fa-brands fa-x-twitter fw-600 hover-green cursor-pointer transition-03"></i>
                </a>
              )}
              {instagramLink && (instagramLink !== 'null') && (
                <a href={instagramLink} target="_blank" className="d-flex align-center  dark-color" >
                  <i className="fa-brands fa-instagram fw-600 hover-green cursor-pointer transition-03"></i>
                </a>
              )}
              {faceBookLink && (faceBookLink !== 'null') && (
                <a href={faceBookLink} target="_blank" className="d-flex align-center  dark-color" >
                  <i className="fa-brands fa-facebook-f fw-600 hover-green cursor-pointer transition-03"></i>
                </a>
              )}
              {linkedInLink && (linkedInLink !== 'null') && (
                <a href={linkedInLink} target="_blank" className="d-flex align-center  dark-color" >
                  <i className="fa-brands fa-linkedin-in fw-600 hover-green cursor-pointer transition-03"></i>
                </a>
              )}
            </div>
            {/* <div className="border-grey h-full h-20"></div> */}
            {/* <select
              onChange={(e) => fnChangeLanguage(e?.target?.value)}
              className="border-grey p-4-0 border-none dark-color w-80 outline-none background-trans"
            >
              <option value={en} selected={selectedLanguage === en}>
                English
              </option>
              <option value={da} selected={selectedLanguage === da}>
                Danish
              </option>
            </select> */}

            {/* <div className="border-grey h-full h-20"></div> */}
            <div
              className="d-flex align-center gap-8 dark-color hover-green cursor-pointer transition-03"
              onClick={() => fnHandlePopups()}
              style={{ cursor: "pointer" }}
            >
              <i className="fa-solid fa-user"></i>
              <span className="f-12">{isLoggedIn ? <DZText children={'Common.logout'} /> : <DZText children={'Common.login'} />}</span>
            </div>
          </div>
        </div>
      </div>
      <ViewPopUps handlePopUps={handlePopUps} />
    </>
  );
};

export default TopBar;
