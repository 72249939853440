import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import "./gallery.css";
import { useGetGalleriesQuery } from "../../redux/storeApis";
import { Config } from "../../constants/Index";

const Gallery = () => {
  const { data: res_get } = useGetGalleriesQuery();
  const [gallerySlider, setGallerySlider] = useState(false);
  const [initialSlideIndex, setinitialSlideIndex] = useState(0);

  const galleryElemetCounter = res_get?.data?.gallery_images?.length;

  const fnApplyGridStyle =()=> {
    return galleryElemetCounter == 1 ? 'oneiscoming' 
    : galleryElemetCounter == 2 ? 'twoiscoming' 
    : galleryElemetCounter == 3 ? 'threeiscoming' 
    : galleryElemetCounter == 4 ? 'fouriscoming' 
    : ''
  };

  return (
    <div className="d-flex items-center justify-center w-full">

      {res_get?.data?.gallery_images?.length > 0 && (
        <div
          style={{ gap: '24px', alignItems: 'center', padding: '48px 0', width: '100%' }}
          className="d-flex flex-col gallery__section max-inner"
        >
          <div className='gallery__container'>
            {res_get?.data?.gallery_images?.map((image, index) => {
              if (index > 4) return null;
              const src = image?.image;
              return (
                <div
                  className={`gallery__image cursor__p ${ fnApplyGridStyle() }`}
                  onClick={() => { setGallerySlider(true); setinitialSlideIndex(index); console.log(index) }}
                >
                  <img
                    src={`${Config.linkGalleryLargeImg}${src}`}
                    alt="#"
                    className="img"
                    style={{
                      minWidth: "100%",
                      minHeight: "100%",
                      objectFit: "cover",
                    }}
                  />
                </div>
              );
            })}

          </div>

          <div
            style={{
              display: gallerySlider ? "flex" : "none",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
              position: "fixed",
              top: "0",
              right: "0",
              bottom: "0",
              left: "0",
              padding: "32px",
              zIndex: "9999",
              backgroundColor: "black",
            }}
          >
            <i
              onClick={() => setGallerySlider(false)}
              className="ri-close-line"
              style={{
                fontSize: "24px",
                color: "#FFF",
                cursor: "pointer",
                position: "absolute",
                right: "10px",
                top: "10px",
                transition: "color 0.3s",
                ":hover": { color: "#EEE" },
                "@media (max-width: 640px)": { top: "5px", right: "5px" },
              }}
            ></i>
            <Swiper
              spaceBetween={10}
              navigation={true}
              modules={[FreeMode, Navigation, Thumbs]}
              className="sliderServiceDetail"
              key={initialSlideIndex}
              initialSlide={initialSlideIndex}
              style={{ "--swiper-navigation-color": "#fff", "--swiper-pagination-color": "#fff", maxWidth: "1200px", width: "100%", maxHeight: "100%", height: "100%", borderRadius: "10px", background: 'black' }}
            >
              {res_get?.data?.gallery_images?.map((image, index) => {
                if (index > 4) return null;
                const src = image?.image;
                return (
                  <SwiperSlide
                    key={index}
                    style={{
                      height: "100%",
                      overflow: "hidden",
                      borderRadius: "10px",
                      background: 'black'
                    }}
                  >
                    <div className="w-full h-full flex-col d-flex align-center">
                      <img
                        src={`${Config.linkGalleryLargeImg}${src}`}
                        alt=""
                        style={{
                          height: '100%',
                          borderRadius: "10px",
                          width: 'auto'
                        }}
                      />
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
